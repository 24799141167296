import {
  COLUMN_CLICK_TYPE,
  COLUMN_TYPE,
  GridColumnSchema,
} from '@maersk-global/angular-shared-library';

export default Object.freeze({
  workOrderSchema: [
    {
      column: 'workOrderNumber',
      sequence: 1,
      displayName: 'Work Order',
      columnType: COLUMN_TYPE.STRING,
      isClickable: false,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'shopCode',
      sequence: 2,
      displayName: 'Shop Code',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderCreatedTimeStamp',
      sequence: 3,
      displayName: 'WO Created On',
      columnType: COLUMN_TYPE.DATE,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'id',
      sequence: 4,
      displayName: 'Case ID',
      columnType: COLUMN_TYPE.STRING,
      isClickable: true,
      columClickType: COLUMN_CLICK_TYPE.LINK_CLICK,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'caseStatus',
      sequence: 5,
      displayName: 'Case Status',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderTotalCostUsd',
      sequence: 6,
      displayName: 'Work Order Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'recoverableCostInUsd',
      sequence: 7,
      displayName: 'Recoverable Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'groupId',
      sequence: 8,
      displayName: 'Group Id',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
      hidden: true,
    } as GridColumnSchema,
    {
      column: 'workOrderGcssChargeCode',
      sequence: 9,
      displayName: 'GCSS Charge Code',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderRecoverableUsdCost',
      sequence: 10,
      displayName: 'Customer Recoverable Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderWithinCoverageCostInUsd',
      sequence: 11,
      displayName: 'Customer Within Coverage Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderAboveCoverageCostInUsd',
      sequence: 12,
      displayName: 'Customer Above Coverage Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
  ],
});

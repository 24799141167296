<div>
  <form [formGroup]="claimForm">
    <mc-modal
      heading="status"
      (closing)="onModelClose($event)"
      [open]="true"
      escapecloseactiondisabled
      backdropcloseactiondisabled>
      <div slot="heading" class="heading">
        {{ column === 'claimStatusId' ? 'Status' : 'Comments' }}
        <mc-text-and-icon
          slot="trigger"
          label="CASE: {{ this.statusDetails?.caseNumber }}"
          icon="briefcase"
          size="small"></mc-text-and-icon>
      </div>

      <div>
        <mc-textarea
          ngDefaultControl
          label=" {{ StatusModalView.subHeaderText }}"
          id="comments"
          name="comments"
          [rows]="4"
          [maxlength]="100"
          formControlName="comments"
          placeholder="Enter your comments here"
          class="text-area-box"></mc-textarea>
      </div>
      <mc-button
        slot="primaryAction"
        appearance="primary"
        (click)="onStatusUpdateClick($event)"
        [disabled]="
          selectedStatusCode !== 'Closed'
            ? !claimForm.dirty
            : !(
                claimForm.get('cancelReason')?.value &&
                claimForm.get('cancelReason')?.value.length > 0
              )
        ">
        {{ StatusModalView.buttonText }}
      </mc-button>
      <mc-button
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        (click)="onModelClose($event)">
        Cancel
      </mc-button>
    </mc-modal>
  </form>
</div>

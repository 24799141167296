import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GridComponent } from '@maersk-global/angular-shared-library';
import { StatusDetailModel } from '../../../common/models/status-detail';
import {
  COMMENTS_MODAL_SUB_HEADER,
  STATUS_MODAL_SUB_HEADER,
} from '../../../common/constants/app.constants';
import { StatusModalView } from '../../../common/models/status-modal-view';
import { ClaimStatus } from '../../../common/enum/claim-status';
import {
  CancelReasonsList,
  statusTypeMaster,
} from '../../../common/constants/temporary-constant';

@Component({
  selector: 'dcrp-status-modal',
  standalone: true,
  imports: [ReactiveFormsModule, GridComponent, CommonModule],
  templateUrl: './status-modal.component.html',
  styleUrl: './status-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StatusModalComponent implements OnInit {
  @Input({ required: true }) statusDetails?: StatusDetailModel;

  @Output() updatedData = new EventEmitter<StatusDetailModel>();

  @Output() onModalCancel = new EventEmitter<boolean>();

  StatusModalView = {
    subHeaderText: '',
    buttonText: '',
    textAreaHeight: '',
  } as StatusModalView;

  claimForm: FormGroup = new FormGroup({
    claimStatus: new FormControl('', {
      validators: Validators.required,
      updateOn: 'change',
    }),
    cancelReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });

  newStatusList: string[] = statusTypeMaster
    .filter((i) => !i.hide)
    .map((status) => status.label);
  cancelReasonsList: string[] = CancelReasonsList;
  selectedStatusCode?: string;
  column?: string;

  ngOnInit(): void {
    this.selectedStatusCode =
      ClaimStatus[this.statusDetails?.claimStatusId || 1];
    if (this.selectedStatusCode) this.column = this.statusDetails?.column || '';
    this.claimForm.setValue({
      claimStatus: this.statusDetails?.claimStatusId,
      cancelReason: this.statusDetails?.cancelationReason,
      comments: this.statusDetails?.comment,
    });

    if (this.column === 'claimStatusId') {
      this.StatusModalView = {
        subHeaderText: STATUS_MODAL_SUB_HEADER,
        buttonText: 'Update Status',
        textAreaHeight: '4',
      } as StatusModalView;
    } else {
      this.StatusModalView = {
        subHeaderText: COMMENTS_MODAL_SUB_HEADER,
        buttonText: 'Save changes',
        textAreaHeight: '5',
      } as StatusModalView;
    }

    // MDS components mark form control property from inside as dirty on form load.
    // To make sure we mark them untouched and pristine, we are using SetTimeOut.
    setTimeout(() => {
      this.claimForm.markAsUntouched();
      this.claimForm.markAsPristine();
    });
  }

  onStatusUpdateClick(event: any) {
    event.preventDefault();
    if (!this.claimForm.valid) return;
    const { claimStatus, comments, cancelReason } = this.claimForm.value;
    this.updatedData.emit({
      cancelationReason: cancelReason ?? '',
      claimStatusId: ClaimStatus[claimStatus as keyof typeof ClaimStatus],
      comment: comments,
      userName: this.statusDetails?.userName,
      caseNumber: this.statusDetails?.caseNumber,
      column: this.statusDetails?.column,
      rowNumber: this.statusDetails?.rowNumber,
    } as StatusDetailModel);
  }

  onModelClose(_: any) {
    this.claimForm.reset();
    this.onModalCancel.emit(true);
  }

  caseStatusChanged(event: any) {
    this.selectedStatusCode = event.target.value;
    this.claimForm
      .get('cancelReason')
      ?.setValue(this.statusDetails?.cancelationReason);
    this.claimForm.markAsTouched();
  }
}

import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { Theme } from './common/services/theme/theme';
import { ThemeService } from './common/services/theme/theme.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContentComponent,
  EmailModalComponent,
  HeaderComponent,
  LoaderComponent,
  ModalNotificationComponent,
  SidenavComponent,
  ToasterComponent,
} from '@maersk-global/angular-shared-library';

import { environment } from '../environments/environment';

import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-core/mc-checkbox';
import '@maersk-global/mds-components-core/mc-pagination';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-option';
import '@maersk-global/mds-components-core/mc-select-native';
import '@maersk-global/mds-components-core/mc-tooltip';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-input-date';
import '@maersk-global/mds-components-core/mc-multi-select';
import '@maersk-global/mds-components-core/mc-multi-select-native';
import '@maersk-global/mds-components-core/mc-typeahead';
import '@maersk-global/mds-components-community/mc-c-tag-multiselect';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-radio-group';
import '@maersk-global/mds-components-core/mc-radio';
import '@maersk-global/mds-components-core/mc-textarea';
import '@maersk-global/mds-components-core/mc-toast';
import '@maersk-global/mds-components-core/mc-notification';
import '@maersk-global/mds-components-core/mc-tab-bar';
import '@maersk-global/mds-components-core/mc-tab';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-community/mc-c-accordion-item';
import '@maersk-global/mds-components-core/mc-text-and-icon';
import '@maersk-global/mds-components-core-drawer';
import '@maersk-global/mds-components-core-button';

import '@maersk-global/insightshub-embed-common/src/css/popup.css';
import { HelpFaqDrawerComponent } from './components/help-faq/help-faq-drawer/help-faq-drawer.component';
import { HelpAndFaqService } from './common/services/help-and-faq/help-and-faq.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    SidenavComponent,
    ContentComponent,
    ModalNotificationComponent,
    ToasterComponent,
    EmailModalComponent,
    LoaderComponent,
    HelpFaqDrawerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'fleet-dcrp-web';
  clickEvent: string = '';
  buttonLabel: string = 'Display Message';
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  isSiteUndermaintenance: boolean = environment.isSiteUndermaintenance;
  isSideNavLoaded: boolean = true;
  constructor(
    private themeService: ThemeService,
    private renderer: Renderer2,
    private router: Router,
    private _helpAnfFaq: HelpAndFaqService
  ) {
    this.themeService.setTheme(this.currentTheme, this.renderer);
    this.title = $localize`${this.title}`;
    if (
      this.router.url !== '/help-faq/user-guide' &&
      this.router.url !== '/help-faq/faq'
    ) {
      this._helpAnfFaq.showSideNavMenu();
    }
  }

  ngOnInit() {
    this._helpAnfFaq.routeListener$.subscribe((value) => {
      this.isSideNavLoaded = value;
    });
  }
}

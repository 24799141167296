import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  TemplateModel,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { getStatusTextFromStatusCode } from '../../../common/constants/temporary-constant';
import {
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  tap,
} from 'rxjs';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { McSelect } from '@maersk-global/mds-components-core';
import { SharedDataService } from '../../../shared-data-service';
import { IsAuthorizedForDirective } from '../../../common/directives/is-authorized-for.directive';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
import { InvoiceDetail } from '../../../common/models/invoiceDetail';
import { UpdateInvoice } from '../../../common/models/updateInvoice';

type InvoiceTemplateExtended = { headerTemplate: Array<TemplateModel> } & {
  template: Array<TemplateModel>;
} & {
  invoice: InvoiceDetail;
  isCancelEnabled: boolean;
  hasInvoicedAmount: boolean;
};

@Component({
  selector: 'invoice-letters-log',
  standalone: true,
  imports: [
    CommonModule,
    PanelComponent,
    LibFormComponent,
    IsAuthorizedForDirective,
  ],
  templateUrl: './invoice-letters-log.component.html',
  styleUrl: './invoice-letters-log.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvoiceLettersLogComponent {
  showCancelInvoiceModal: boolean = false;
  enableIssueNewInvoiceButton: boolean = false;
  currentSelectedInvoice?: InvoiceDetail;

  invoicesExtended$: Observable<InvoiceTemplateExtended[] | undefined> =
    this._sharedRecoveryCaseService.invoices$.pipe(
      map((invoices) =>
        invoices
          ?.map((invoice, index) => {
            return {
              headerTemplate: this.prepareTemplateModelsForInvoicesHeader(
                invoice,
                index + 1
              ),
              template: this.prepareTemplateModelsForInvoices(
                invoice,
                index + 1
              ),
              invoice: invoice,
              hasInvoicedAmount: !(
                invoice.totalInvoiceAmountInCaseCurrency == null
              ),
              isCancelEnabled:
                invoice.invoiceNumber &&
                invoice.invoiceStatusCode &&
                ![
                  InvoiceStatusEnum.CancellationSubmitted,
                  InvoiceStatusEnum.InvoiceCancellationRequestFailed,
                  InvoiceStatusEnum.InvoiceCancellationRequested,
                  InvoiceStatusEnum.InvoiceCancelled,
                ].includes(invoice.invoiceStatusCode),
            } as InvoiceTemplateExtended;
          })
          ?.sort(
            (letter1, letter2) =>
              new Date(letter2.invoice.createdTimestamp ?? '').getTime() -
              new Date(letter1.invoice.createdTimestamp ?? '').getTime()
          )
          .filter((invoice, index) => {
            invoice.isCancelEnabled =
              index == 0 ? invoice.isCancelEnabled : false;
            return true;
          })
      ),
      tap((invoicesExtended) => {
        if (invoicesExtended && invoicesExtended.length > 0) {
          this.enableIssueNewInvoiceButton =
            invoicesExtended[0].invoice?.invoiceStatusCode ==
            InvoiceStatusEnum.InvoiceCancelled
              ? true
              : false;
        } else {
          this.enableIssueNewInvoiceButton = false;
        }
      })
    );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.countries$,
  ]).pipe(
    map(
      ([recoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === recoveryData?.responsibleCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService
  ) {}

  issueNewInvoiceClicked() {
    this._sharedRecoveryCaseService.updateIssueInvoiceVisibility(true);
  }

  onCancelInvoiceButtonClicked(invoice: InvoiceDetail) {
    this.currentSelectedInvoice = invoice;
    this.showCancelInvoiceModal = true;
  }

  async onInvoiceCancelled(mcSelect: HTMLElement) {
    if (!this.currentSelectedInvoice) {
      this._toasterService.showToast({
        message: 'Something went wrong while cancelling invoice!',
        type: 'error',
      });
      return;
    }

    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;

    let invoiceCancellationRequest = {
      ...this.currentSelectedInvoice,
    } as UpdateInvoice;

    invoiceCancellationRequest.cancellationReason = (
      mcSelect as McSelect
    ).value?.toString();
    invoiceCancellationRequest.invoiceStatusCode =
      InvoiceStatusEnum.CancellationSubmitted;
    invoiceCancellationRequest.invoiceStatus = undefined;

    const response = await lastValueFrom(
      this._sharedRecoveryCaseService.putInvoiceDetails(
        this.currentSelectedInvoice.id ?? 0,
        invoiceCancellationRequest,
        userId
      )
    );

    if (response) {
      this._sharedRecoveryCaseService.reloadInvoices();
      this._toasterService.showToast({
        message: 'Invoice cancellation request initiated successfully!',
        type: 'success',
      });
    }
  }

  prepareTemplateModelsForInvoicesHeader(
    invoice: InvoiceDetail,
    index: number
  ) {
    return [
      {
        label: 'Invoiced Amount (' + invoice.caseCurrency + ')',
        value: this._sharedDataService.formatDecimal(
          invoice.totalInvoiceAmountInCaseCurrency
        ),
        type: 'label',
        width: { size: 50, unit: '%' },
        name: 'invoicedAmount',
      } as TemplateModel,
      {
        label: 'Amount Received (' + invoice.caseCurrency + ')',
        value: invoice.paidAmount
          ? this._sharedDataService.formatDecimal(invoice.paidAmount)
          : invoice.paidAmount,
        type: 'label',
        width: { size: 50, unit: '%' },
        name: 'amountReceived',
      } as TemplateModel,
    ];
  }

  prepareTemplateModelsForInvoices(invoice: InvoiceDetail, index: number) {
    return [
      {
        label: 'Serial Number',
        value: index,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'serialNumber',
      } as TemplateModel,
      {
        label: 'Created By',
        value: invoice.createdByUserId,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'createdBy',
      } as TemplateModel,
      {
        label: 'Creation Date',
        value: invoice.createdTimestamp,
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'createdDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Invoice Status',
        value: getStatusTextFromStatusCode(invoice.invoiceStatusCode),
        type: 'label',
        width: { size: 20, unit: '%' },
        name: 'invoiceStatus',
      } as TemplateModel,
      {
        label: 'Invoice Number',
        value: !!invoice.invoiceNumber ? invoice.invoiceNumber : '-',
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'invoiceNumber',
      } as TemplateModel,
      {
        label: 'Payment Received',
        value: invoice.paymentDate ? 'Yes' : 'No',
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'paymentReceived',
      } as TemplateModel,
      {
        label: 'Fact Reference Number',
        value: !!invoice.factReferenceNumber
          ? invoice.factReferenceNumber
          : '-',
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'factReferenceNumber',
      } as TemplateModel,
      {
        label: 'Due Date',
        value: invoice.dueDate,
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'dueDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Payment Date',
        value: invoice.paymentDate,
        type: 'label',
        width: { size: 20, unit: '%' },
        name: 'paymentDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Customer Number',
        value: invoice.factCode,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'factCode',
      } as TemplateModel,
      {
        label: 'Cancellation Reason',
        value: invoice.cancellationReason,
        type: 'label',
        width: { size: 50, unit: '%' },
        name: 'cancellationReason',
        hidden: !invoice.cancellationReason,
      } as TemplateModel,
    ];
  }
}

import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DropDownOption,
  LibFormComponent,
  PanelComponent,
  TemplateModel,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomerInfoDto } from '../../../common/models/customerInfoDto';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { SharedDataService } from '../../../shared-data-service';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { ItemType } from '../../../common/models/itemType';
import { LiabilityPartyService } from '../../../common/services/recovery/liabilityParty.service';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';
import { Components } from '../../../common/constants/temporary-constant';
import { ShopResponse } from '../../../common/models/shopResponse';
import { UpdateLiabilityParty } from '../../../common/models/updateLiabilityParty';
import { SaveLiabilityParty } from '../../../common/models/saveLiabilityParty';
import { BookingService } from '../../../common/services/recovery/booking.service';
import { COUNTRYCODE_MISMATCH_MESSAGE } from '../../../common/constants/app.constants';

const CONSTANTS = {
  SHOP_CODE: 'shopCode',
  LIABLE_PARTY_NAME: 'liablePartyName',
  FACT_CODE: 'factCode',
  SCV_CODE: 'scvCode',
  OTHERS: '-1',
  SHOP_NAME: 'shopName',
  CONTACT_EMAIL: 'primaryContactEmail',
  SHOP_LOCATION_CODE: 'shopLocationCode',
  CONTACT_NAME: 'contactName',
  PLACE_OF_DELIVERY: 'placeOfDelivery',
  PLACE_OF_RECEIPT: 'placeOfReceipt',
};

@Component({
  selector: 'liability-party-vendor-details',
  standalone: true,
  imports: [CommonModule, LibFormComponent, PanelComponent],
  templateUrl: './liability-party-vendor-details.component.html',
  styleUrl: './liability-party-vendor-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LiabilityPartyVendorDetailsComponent {
  @Input() item!: TemplateModel;
  @Output() onLiabilityPartySelected = new EventEmitter<
    SendMailRequest | undefined
  >();

  apiVersion: string = '1.0';
  validFactCode: boolean = true;
  shopCountryCode: string = '';
  notificationMsg: string = COUNTRYCODE_MISMATCH_MESSAGE;
  constructor(
    private _commonService: CommonService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _liabilityPartyService: LiabilityPartyService,
    private _vendorLiabilitySharedService: SharedVendorRecoveryCaseService,
    private _bookingService: BookingService,
    private _toasterService: ToasterService
  ) {}

  /**
   * Behavior subject to hold the latest liability party type selected.
   */

  currentSelectedShopCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('-1');
  /**
   * Behavior subject to hold the latest fact code entered by the user.
   */
  currentEnteredFactCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * Behavior subject to hold the latest scv code entered by the user.
   */
  currentEnteredSCVCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * Behavior subject to hold the latest scv code entered by the user.
   */
  currentEnteredShopLocationCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  hasNewPartyTypeSelectedByUser: boolean = false;

  /**
   * This observable always holds the latest customer details for the selected customer (either via liability party name dropdown or fact code text input).
   * It emits null if no value is selected.
   * */
  customerDetails$: Observable<CustomerInfoDto | null> = merge(
    this.currentEnteredFactCode$$.asObservable().pipe(debounceTime(500)),
    this.currentEnteredSCVCode$$.asObservable().pipe(debounceTime(500))
  ).pipe(
    switchMap((customerCode) => {
      if (!customerCode || customerCode.length < 5) return of(null);
      return this._commonService
        .commonCustomersCmdCustomerCodeGet(
          customerCode,
          this.currentEnteredFactCode$$.value !== customerCode &&
            this.currentEnteredSCVCode$$.value
            ? 'SCV'
            : undefined,
          this.apiVersion
        )
        .pipe(
          map((response) => response.data ?? null),
          catchError(() => of(null))
        );
    }),
    tap((customerDetails) => {
      if (customerDetails) {
        this.validateFactCode(customerDetails.factCode);
        this._sharedRecoveryCaseService.updateCustomerData(customerDetails);
      }
    }),
    shareReplay(1)
  );

  shopCodes$: Observable<null> = of(null);

  selectedShopName$: Observable<string> =
    this.currentEnteredShopLocationCode$$.pipe(
      switchMap((locationId) => {
        if (!locationId) return of('');
        return this._sharedDataService.shopCodes$.pipe(
          map((shops) => {
            return (
              shops?.filter((i) => i.locationId == locationId)[0].description ??
              ''
            );
          }),
          catchError(() => of(''))
        );
      })
    );

  onShopLocationSearch(value: string): Observable<DropDownOption[]> {
    return this._sharedDataService.shopCodes$.pipe(
      map((response: ShopResponse[] | undefined) => {
        // Return an empty array if there's no response or no assigned users
        if (!response || response.length == 0) return [];
        // Map the response to dropdown options
        const filteredShop = response.filter((i) =>
          i.locationId?.includes(value)
        );
        const shops = filteredShop?.map((site) => ({
          value: site.locationId,
          label: `${site.locationId} - ${site.description}`,
        }));
        return shops;
      }),
      catchError(() => of([]))
    );
  }

  /**
   * This observable holds the saved liability party details of this case on server.
   * If party details are saved already, we will pre-populated the saved values as current selected values.
   */
  savedLiablePartyData$ =
    this._vendorLiabilitySharedService.vendorRecoveryLiablePartyData$.pipe(
      map((data) => data ?? null),
      tap((data) => {
        this.currentSelectedShopCode$$.next(
          '-1' // Shop code with only others as a dropdown
        );
        if (!!data?.liablePartyLocationCode)
          this.currentEnteredShopLocationCode$$.next(
            data.liablePartyLocationCode
          );
        if (!!data?.factCustomerCode)
          this.currentEnteredFactCode$$.next(data.factCustomerCode);
        if (!!data?.scvCustomerCode)
          this.currentEnteredSCVCode$$.next(data.scvCustomerCode);
      }),
      take(1),
      shareReplay(1)
    );

  /**
   * Observable holds the party types as dropdown items for the current case.
   */
  shopCodeAsDropDownItems$: Observable<DropDownOption[] | undefined> =
    this.shopCodes$.pipe(
      map(() => {
        const dropDownItems = [];
        dropDownItems.push({
          label: 'Others',
          value: '-1',
        });
        return dropDownItems ?? [];
      }),
      shareReplay(1)
    );

  /**
   * This method populates the first root element i.e. liability party type dropdown.
   * It sets the initial value if liability party details is already saved.
   * @returns void
   */
  async populateShopCodeDropDown() {
    const currentPartyType = this.currentSelectedShopCode$$.value;
    //if (!!currentPartyType) return;
    const liablePartyData = await firstValueFrom(this.savedLiablePartyData$);
    const caseRecoveryDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );
    this.shopCountryCode = caseRecoveryDetails.shopCountryCode ?? '';
    const shopCodeSection = this.item.items?.[0];
    shopCodeSection?.items?.forEach(async (item) => {
      if (item.name == CONSTANTS.SHOP_LOCATION_CODE) {
        item.OptionAsync = (value) => this.onShopLocationSearch(value);
        item.value = liablePartyData?.liablePartyLocationCode
          ? liablePartyData?.liablePartyLocationCode
          : this.currentEnteredShopLocationCode$$.value;
        item.onValueChanged = async () => {
          if (!item.value) return;
          this.currentEnteredShopLocationCode$$.next(item.value);
        };
      }
      if (item.name === CONSTANTS.SHOP_CODE) {
        item.options = await firstValueFrom(this.shopCodeAsDropDownItems$);
        item.hidden = false;
        item.value =
          item.options?.length === 1 && item.options[0].value === '-1'
            ? '-1'
            : currentPartyType || '';
        item.onValueChanged = (value) => {
          // If user has not selected new party type or value is null, do not emit the dependent selections as empty.
          if (
            !value ||
            (value === liablePartyData?.liablePartyTypeID &&
              !this.hasNewPartyTypeSelectedByUser)
          ) {
            return;
          }
          this.validFactCode = true;
          this.hasNewPartyTypeSelectedByUser = true;
          // We will make the other dependent selections empty when this dropdown value changes.
          this.currentSelectedShopCode$$.next(value);
          this.onLiabilityPartySelected.emit();
        };
      }
      // Lets make all other fields of liablePartySelectionSection hidden.
      if (
        [
          CONSTANTS.SCV_CODE,
          CONSTANTS.FACT_CODE,
          CONSTANTS.CONTACT_EMAIL,
        ].includes(item.name)
      ) {
        item.value = null;
        item.hidden = true;
      }
    });
  }
  /**
   * This method disables the form fields if this case is found closed.
   * @param customerRecoveryData recovery case data
   */
  disableAllFieldsIfCaseIsClosed(disable: boolean) {
    this.item?.items?.forEach(async (section: TemplateModel) => {
      section.items?.forEach(async (subSection: TemplateModel) => {
        subSection.disabled = disable;
      });
    });
  }

  /**
   * This method will populate liability party names dropdown or fact code text input based on liability party type selection.
   */
  async populateFactCodeSCVCodeTextBox(
    customerDetails: CustomerInfoDto | null
  ) {
    const currentPartyType = this.currentSelectedShopCode$$.value;
    const currentFactCode = this.currentEnteredFactCode$$.value;
    const currentSCVCode = this.currentEnteredSCVCode$$.value;
    const liableShopCodeSelectionSection = this.item.items?.[0];
    liableShopCodeSelectionSection?.items?.forEach(async (item) => {
      if (item.name === CONSTANTS.FACT_CODE) {
        item.hidden = !(
          !!currentPartyType && currentPartyType === CONSTANTS.OTHERS
        );
        item.value = currentFactCode || null;
        item.onValueChanged = (value) => {
          if (value === customerDetails?.factCode) return;
          this.currentEnteredFactCode$$.next(value);
          this.onLiabilityPartySelected.emit();
          this.validateFactCode(value);
        };
      }

      if (item.name === CONSTANTS.SCV_CODE) {
        item.hidden = !(
          !!currentPartyType && currentPartyType === CONSTANTS.OTHERS
        );
        item.value = currentSCVCode || '';
        item.onValueChanged = (value) => {
          if (value === customerDetails?.scvCode) return;
          this.currentEnteredSCVCode$$.next(value);
          this.onLiabilityPartySelected.emit();
        };
      }
    });
  }
  validateFactCode(factCode: string | undefined) {
    this.validFactCode = true;
    const currentPartyType = this.currentSelectedShopCode$$.value;
    if (currentPartyType == CONSTANTS.OTHERS) {
      const countryCode = factCode?.substring(0, 2);
      if (countryCode && countryCode !== this.shopCountryCode) {
        this.validFactCode = false;
      }
    }
  }

  templateModelForm$: Observable<TemplateModel[] | undefined> = combineLatest([
    this.customerDetails$,
    this.selectedShopName$,
    this._vendorLiabilitySharedService.disableForm$,
  ]).pipe(
    switchMap(async ([customerDetails, shopName, disable]) => {
      this.disableAllFieldsIfCaseIsClosed(disable);
      await this.populateShopCodeDropDown();
      await this.populateFactCodeSCVCodeTextBox(customerDetails);
      await this.populateDependentFieldsOfAllSections(
        customerDetails,
        shopName
      );
      return this.item.items;
    })
  );

  /**
   * This method populates all the other form fields which were dependent on liability Shop Code
   * @param customerDetails selected customer details
   * @returns
   */
  async populateDependentFieldsOfAllSections(
    customerDetails: CustomerInfoDto | null,
    shopName: string
  ) {
    if (!customerDetails) {
      this.hideDependentFieldsOfAllSections();
      return;
    }

    const masterContactKeyValue = customerDetails.contacts?.filter(
      (contact) => contact.isMasterContact
    )[0] as {
      [key: string]: unknown;
    };
    const customerKeyValue = customerDetails as {
      [key: string]: unknown;
    };

    await this.populateDependentFieldsOfShopCodeSelectionSection(
      masterContactKeyValue,
      customerKeyValue
    );
    this.populateDependentFieldsOfCustomerDetailsSection(
      masterContactKeyValue,
      customerKeyValue,
      shopName
    );
    await this.populateDependentFieldsOfPartyDetailsSection(customerKeyValue);

    await this.emitLiabilityPartySelection(masterContactKeyValue, shopName);
  }

  /**
   * This method emits valid liability party selection which will be used in liability letter component.
   * @param masterContactKeyValue  master contact fields as key value
   * @param shopName shop name
   */
  async emitLiabilityPartySelection(
    masterContactKeyValue: { [key: string]: unknown },
    shopName: string
  ) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const loggedUserEmail = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).email;
    await firstValueFrom(this.savedLiablePartyData$);
    const caseRecoveryDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );
    const liabilityEmailLetterContent = {
      to: masterContactKeyValue[CONSTANTS.CONTACT_EMAIL] as string,
      from: loggedUserEmail,
      body: '',
      caseNumber: caseRecoveryDetails?.recoveryCaseNumber ?? '',
      emailType: ItemType.VendorEmails,
      subject: '',
      liabilityLetter: {
        createdBy: userId,
        createdDate: new Date(),
        liabilityAmount: 0,
        liabilityPartyName:
          shopName ||
          (masterContactKeyValue[CONSTANTS.CONTACT_NAME] as string) ||
          '',
        recoveryCaseId: caseRecoveryDetails.caseId ?? 0,
      },
    };
    this.onLiabilityPartySelected.emit(liabilityEmailLetterContent);
  }

  /**
   * This method populates the second section i.e. customerDetailsSection
   * @param masterContactKeyValue master contact fields as key value
   * @param customerKeyValue customer details as key value
   */
  populateDependentFieldsOfCustomerDetailsSection(
    masterContactKeyValue: { [key: string]: unknown },
    customerKeyValue: { [key: string]: unknown },
    shopName: string
  ) {
    const customerDetailsSection = this.item.items?.[1];
    if (customerDetailsSection) customerDetailsSection.hidden = false;
    customerDetailsSection?.items?.forEach((item) => {
      if (item.name === CONSTANTS.CONTACT_NAME)
        item.value = masterContactKeyValue[item.name];
      else
        item.value =
          item.name == CONSTANTS.SHOP_NAME
            ? shopName || null
            : customerKeyValue[item.name];
    });
  }

  /**
   * This method populates the first section i.e. liablePartySelectionSection
   * @param masterContactKeyValue master contact fields as key value
   * @param customerKeyValue customer details as key value
   */
  async populateDependentFieldsOfShopCodeSelectionSection(
    masterContactKeyValue: {
      [key: string]: unknown;
    },
    customerKeyValue: { [key: string]: unknown }
  ) {
    const liablePartySelectionSection = this.item.items?.[0];
    const party = await firstValueFrom(this.savedLiablePartyData$);
    liablePartySelectionSection?.items?.forEach((item) => {
      if (item.name === CONSTANTS.CONTACT_EMAIL) {
        item.hidden = false;
        item.value = !!party
          ? party.customerEmailAddress
          : masterContactKeyValue[item.name];
      }
      if (item.name === CONSTANTS.FACT_CODE) {
        item.value = customerKeyValue[item.name];
      }

      if (item.name === CONSTANTS.SCV_CODE) {
        item.value = customerKeyValue[item.name];
      }
    });
  }

  /**
   * This method populates the third section i.e. partyDetailsSection
   * @param customerKeyValue customer details as key value
   */
  async populateDependentFieldsOfPartyDetailsSection(customerKeyValue: {
    [key: string]: unknown;
  }) {
    const partyDetailsSection = this.item.items?.[2];

    if (partyDetailsSection) partyDetailsSection.hidden = false;
    else return;

    const party = await firstValueFrom(this.savedLiablePartyData$);
    let placeOfDelivery = party?.placeOfDelivery;
    let placeOfReceipt = party?.placeOfReceipt;

    if (!party) {
      const caseRecoveryDetails = await firstValueFrom(
        this._sharedRecoveryCaseService.recoveryCaseData$
      );
      const bookNumber =
        caseRecoveryDetails.bolNumber ?? caseRecoveryDetails.bookingNumber;
      const carrierCodes = await firstValueFrom(
        this._sharedDataService.carrierCodes$
      );
      const operatorCode = caseRecoveryDetails.operatorCode ?? 'MSK';
      const carrierCode =
        carrierCodes.find((i) => i.operatorCode == operatorCode)?.carrierCode ??
        'MAEU';
      const bookingDetail = await lastValueFrom(
        this._bookingService
          .bookingTransportPlanGet(bookNumber ?? '', carrierCode)
          .pipe(
            map((res) => res),
            catchError(() => {
              this._toasterService.showToast({
                message: 'POL and POD not available',
                type: 'error',
              } as Toast);
              return of(null);
            })
          )
      );
      placeOfDelivery = bookingDetail?.podSiteCode;
      placeOfReceipt = bookingDetail?.polSiteCode;
    }

    partyDetailsSection?.items?.forEach((item) => {
      if (
        item.name === CONSTANTS.PLACE_OF_DELIVERY ||
        item.name === CONSTANTS.PLACE_OF_RECEIPT
      ) {
        item.OptionAsync = (value) =>
          this._sharedDataService.onGeoLocationTypeAheadSearched(value);
        item.value =
          (item.name === CONSTANTS.PLACE_OF_DELIVERY
            ? placeOfDelivery
            : placeOfReceipt) ?? '';
      } else item.value = customerKeyValue[item.name];
    });
  }

  /**
   * Method hides all dependent fields from all the sections if no customer details available.
   */
  hideDependentFieldsOfAllSections() {
    const liableShopCodeSection = this.item.items?.[0];
    liableShopCodeSection?.items?.forEach((item) => {
      if (CONSTANTS.CONTACT_EMAIL === item.name) item.hidden = true;
    });
    const customerDetailsSection = this.item.items?.[1];
    if (customerDetailsSection) customerDetailsSection.hidden = true;
  }

  async saveLiabilityParties() {
    const caseRecoveryDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );

    const liabilityPartySelectionSection = this.item?.items?.[0];
    const liabilityPartySection = this.item?.items?.[1];
    const partyDetailsSection = this.item?.items?.[2];
    const savedLiablePartyData = await firstValueFrom(
      this.savedLiablePartyData$
    );

    let liablePartyRequest = {
      scvCustomerCode: liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'scvCode'
      )[0].value,
      factCustomerCode: liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'factCode'
      )[0].value,
      liablePartyLocationCode: liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'shopLocationCode'
      )[0].value,
      liablePartyName:
        liabilityPartySection?.items?.filter((i) => i.name == 'shopName')[0]
          .value ??
        liabilityPartySection?.items?.filter((i) => i.name == 'contactName')[0]
          .value,
      caseID: caseRecoveryDetails.caseId,
      customerEmailAddress: liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'primaryContactEmail'
      )[0].value,
      placeOfDelivery: partyDetailsSection?.items?.filter(
        (i) => i.name == 'placeOfDelivery'
      )[0].value,
      placeOfReceipt: partyDetailsSection?.items?.filter(
        (i) => i.name == 'placeOfReceipt'
      )[0].value,
      payers: partyDetailsSection?.items?.filter((i) => i.name == 'payers')[0]
        .value,
      billToParty: partyDetailsSection?.items?.filter(
        (i) => i.name == 'billTo'
      )[0].value,
      shipToParty: partyDetailsSection?.items?.filter(
        (i) => i.name == 'shipTo'
      )[0].value,
      soldToParty: partyDetailsSection?.items?.filter(
        (i) => i.name == 'soldTo'
      )[0].value,
    } as SaveLiabilityParty;

    const saveParties = savedLiablePartyData?.id
      ? await lastValueFrom(
          this._liabilityPartyService.liabilityPartiesIdPut(
            savedLiablePartyData.id,
            liablePartyRequest as UpdateLiabilityParty
          )
        )
      : await lastValueFrom(
          this._liabilityPartyService.liabilityPartiesPost(liablePartyRequest)
        );

    return saveParties;
  }

  formValidityChanged(isValid: boolean) {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.LiabilityPartyVendorDetailsComponent,
      state: isValid && this.validFactCode,
    });
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from '../../custom-workflow/attachment/attachment.component';
import { OverviewComponent } from '../../custom-workflow/overview/overview.component';
import { BookingInfoComponent } from '../../custom-workflow/booking-info/booking-info.component';
import { ContainerInfoComponent } from '../../custom-workflow/container-info/container-info.component';
import { CargoDetailsComponent } from '../../custom-workflow/cargo-details/cargo-details.component';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { LiabilityDetailsComponent } from '../../custom-workflow/liability-details/liability-details.component';
import { DamageDetailsComponent } from '../../custom-workflow/damage-details/damage-details.component';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Router } from '@angular/router';
import { ActivityLogComponent } from '../../custom-workflow/activity-log/activity-log.component';
import { WorkOrdersComponent } from '../../custom-workflow/work-orders/work-orders.component';
import {
  Loader,
  LoaderService,
  ModalNotificationService,
  PageSkeletonLoaderComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { LiablePartyDetailsComponent } from '../../custom-workflow/liable-party-details/liable-party-details.component';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { ClaimStatusRequest } from '../../../common/models/claim-status-request';
import { LiabilityLetterComponent } from '../../custom-workflow/liability-letter/liability-letter.component';
import { LiabilityLettersLogComponent } from '../../custom-workflow/liability-letters-log/liability-letters-log.component';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { InvoiceLettersLogComponent } from '../../custom-workflow/invoice-letters-log/invoice-letters-log.component';
import { CompletedComponent } from '../../custom-workflow/completed/completed.component';
import { CaseAssignmentStatusResponse } from '../../../common/models/caseAssignmentStatusResponse';
import { InvoiceSummaryComponent } from '../../custom-workflow/invoice-summary/invoice-summary.component';
import { InvoiceLetterComponent } from '../../custom-workflow/invoice-letter/invoice-letter.component';
import {
  CancelReasonsList,
  CaseTypeEnum,
  workflowStages,
} from '../../../common/constants/temporary-constant';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ClaimStatusResponse } from '../../../common/models/claim-status-response';
import { WorkFlowStage } from '../../../common/models/workflowStage';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { ManualEstimatesComponent } from '../../custom-workflow/manual-estimates/manual-estimates.component';
import { LiabilityDetailsManualEstimatesComponent } from '../../custom-workflow/liability-details-manual-estimates/liability-details-manual-estimates.component';
import { CpInfoComponent } from '../../custom-workflow/cp-info/cp-info.component';
import {
  AUTO_CLOSE_REASON,
  CREATE_VENDOR_RECOVERY_CASE_MSG,
} from '../../../common/constants/app.constants';
import { CountryDto } from '../../../common/models/countryDto';
import { StepperComponent } from '../../custom-workflow/stepper/stepper.component';
import { CustomerRecoveryOrchestratorWorkflowManager } from './customer-recovery-orchestrator-workflow-manager';
import { recoveryTab } from '../../../common/enum/recovery-tab';
import { SharedCustomerRecoveryCaseService } from '../shared-customer-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';
import { IsAuthorizedForDirective } from '../../../common/directives/is-authorized-for.directive';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
import { RecoveryCase } from '../../../common/models/recoveryCase';
import { RecoveryCaseService } from '../../../common/services/recovery/recovery.service';
import { CreateRecoveryCase } from '../../../common/models/createRecoveryCase';
import { VendorRecoveryCaseResponse } from '../../../common/models/vendorRecoveryCaseResponse';
import { CancellationReasonsDto } from '../../../common/models/cancellationReasonsDto';
@Component({
  selector: 'customer-recovery-orchestrator',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StepperComponent,
    AttachmentComponent,
    OverviewComponent,
    BookingInfoComponent,
    CpInfoComponent,
    ContainerInfoComponent,
    CargoDetailsComponent,
    LiabilityDetailsComponent,
    DamageDetailsComponent,
    ManualEstimatesComponent,
    ActivityLogComponent,
    WorkOrdersComponent,
    LiablePartyDetailsComponent,
    LiabilityLetterComponent,
    LiabilityLettersLogComponent,
    InvoiceLettersLogComponent,
    CompletedComponent,
    InvoiceLetterComponent,
    InvoiceSummaryComponent,
    LiabilityDetailsManualEstimatesComponent,
    PageSkeletonLoaderComponent,
    IsAuthorizedForDirective,
  ],
  templateUrl: './customer-recovery-orchestrator.component.html',
  styleUrl: './customer-recovery-orchestrator.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomerRecoveryOrchestratorComponent {
  stages: TemplateModel[] | undefined;
  @ViewChild('letterComponent', { static: false })
  letterComponent!: LiabilityLetterComponent;

  @ViewChild('invoice', { static: false })
  invoiceLetterComponent!: InvoiceLetterComponent;

  @ViewChild('party', { static: false })
  partyComponent!: LiablePartyDetailsComponent;

  @ViewChild('damageDetails', { static: false })
  damageDetailsComponent!: DamageDetailsComponent;

  @ViewChild('workOrdersComponent', { static: false })
  workOrdersComponent!: WorkOrdersComponent;

  currentStageId: number = 1;
  bookingCargoDetail!: gcssBookingInfo;
  caseDetails!: RecoveryCase;
  apiVersion: string = '1.0';
  caseNumber: string = '';
  currentTabIndex: number = 0;
  tabItems?: TemplateModel[];
  workOrders!: WorkOrderAndLineItemsDto[];
  nextStageId: number = 0;
  liabilityEmailContent?: SendMailRequest;
  LIABILITY_STAGE_ID: number = 3;
  DAMAGE_ESTIMATION_STAGE_ID: number = 2;
  NEW_STAGE_ID: number = 1;
  loadTab: boolean = true;
  closeCase: boolean = false;
  cancelReasons: string[] = CancelReasonsList;
  currentStageItem: TemplateModel[] = [];
  closeCaseForm: FormGroup = new FormGroup({
    closeReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });
  closedStatusId: number = 6;
  isSaveOperationInProgress: boolean = false;
  OTHERS: string = 'Others';
  closeReason: string = '';

  orchestratorWorkFlowManger?: CustomerRecoveryOrchestratorWorkflowManager;
  tabName$: Observable<string> =
    this._customerRecoverySharedService.currentCustomerRecoveryTabIndex$.pipe(
      map((index) => recoveryTab[index])
    );

  cancellationReasons$?: Observable<CancellationReasonsDto[] | undefined> =
    this._sharedDataService.cancellationReasons$;

  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _recoveryService: RecoveryCaseService,
    private _toaster: ToasterService,
    private _loader: LoaderService,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _modalService: ModalNotificationService
  ) {}

  hasIssuedLiabilityLetters$: Observable<boolean> =
    this._sharedRecoveryCaseService.liabilityLetters$.pipe(
      map((letters) => !!letters && letters.length > 0)
    );

  disableForm$: Observable<boolean> =
    this._customerRecoverySharedService.disableForm$;
  enableCloseBtn$: Observable<boolean> =
    this._customerRecoverySharedService.enableCloseBtn$;
  reopenCase$: Observable<boolean> =
    this._customerRecoverySharedService.reopenCase$;
  enableNextBtn$: Observable<boolean> =
    this._customerRecoverySharedService.enableNextBtn$;
  hideNextBtn$: Observable<boolean> =
    this._customerRecoverySharedService.hideNextBtn$;

  shouldShowLiabilityDetails$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowLiabilityDetails$;

  shouldShowIssueInvoiceScreen$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowIssueInvoiceScreen$;

  countries: CountryDto[] = [];

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.caseDetails = recoveryData!;
        this.caseNumber = recoveryData?.recoveryCaseNumber ?? '';
        this.closeReason =
          recoveryData?.recoveryStatusId === 7
            ? `Reason:  ${recoveryData.comment ?? ''}`
            : recoveryData?.cancellationReason === this.OTHERS
              ? `Reason: ${recoveryData?.cancellationReason ?? ''} - ${recoveryData.comment ?? ''}`
              : `Reason: ${recoveryData?.cancellationReason ?? ''}`;
      })
    );

  currentStageId$ = this._customerRecoverySharedService.currentStageId$.pipe(
    tap((newStageId) => {
      this.currentStageId = newStageId;
      this.orchestratorWorkFlowManger =
        new CustomerRecoveryOrchestratorWorkflowManager(
          this._customerRecoveryService,
          this._sharedRecoveryCaseService,
          this._customerRecoverySharedService,
          this.currentStageId,
          this.caseDetails
        );
      this.loadTabInitialData(this.currentStageId);
    })
  );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.countries$,
  ]).pipe(
    map(
      ([customerRecoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === customerRecoveryData?.podCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  onClosingClick() {
    let reason = this.closeCaseForm.value;

    if (reason.closeReason.enableVendorFlowOption) {
      this._modalService.openModal({
        primaryButtonMessage: 'Yes',
        secondaryButtonMessage: 'No',
        message: CREATE_VENDOR_RECOVERY_CASE_MSG,
        header: 'Create Vendor Recovery Case?',
        dimension: 'medium',
        onPrimaryBtnClick: this.confirmVendorRecoveryCaseCreate.bind(this),
        onSecondaryBtnClick: this.declineVendorRecoveryCaseCreate.bind(this),
      });
    } else {
      this.declineVendorRecoveryCaseCreate();
    }
  }

  async confirmVendorRecoveryCaseCreate() {
    const response: VendorRecoveryCaseResponse | undefined =
      await this.createVendorRecoveryCase();
    await this.closingCase();

    this.navigateToVendorRecoveryCaseWorkFlow(
      response.data?.id ?? 0,
      response.data?.equipmentNumber ?? ''
    );
  }

  async declineVendorRecoveryCaseCreate() {
    await this.closingCase();
    this.navigateToCustomerRecoveryCaseGrid();
  }

  async createVendorRecoveryCase(): Promise<VendorRecoveryCaseResponse> {
    let recoveryCase = await firstValueFrom(this.customerRecoveryData$);

    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;

    recoveryCase.assignedTo = userId;

    const request: CreateRecoveryCase | undefined =
      this._sharedRecoveryCaseService.buildCreateRecoveryCaseRequest(
        recoveryCase,
        'Vendor'
      );

    const response = await firstValueFrom(
      this._recoveryService.recoveryCasesPost(request)
    );

    return response as VendorRecoveryCaseResponse;
  }

  /**
   * Navigates to case workflow page based on case number and container number
   * @param caseNumber - Case number for which workflow needs to be displayed
   * @param containerNumber - Container number for which workflow needs to be displayed
   */
  private navigateToVendorRecoveryCaseWorkFlow(
    caseId: number,
    containerNumber: string
  ) {
    this._router.navigate(['vendor-recovery/workflow/'], {
      queryParams: {
        caseId: caseId,
        containerNumber: containerNumber,
      },
    });
  }

  private navigateToCustomerRecoveryCaseGrid() {
    this._customerRecoverySharedService.updateTabSelected(1);
    this._router.navigate(['customer-recovery']);
  }

  onNavigateBackToCaseList() {
    this._router.navigate(['customer-recovery']);
  }

  loadTabInitialData(stageId: number): void {
    this.stages = this.orchestratorWorkFlowManger?.stages;
    this.currentTabIndex = 0;
    const caseStageTemplate = this.stages?.filter(
      (i) => i.id === stageId
    )[0] as TemplateModel;
    this.currentStageItem =
      caseStageTemplate.items?.filter((i) => i.name === 'tab')[0].items ?? [];
    if (this.currentStageItem)
      this.tabItems = this.currentStageItem[this.currentTabIndex].items;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == stageId)[0].sequence ?? 0;
    if (
      this.stages &&
      (this.stages[this.stages.length - 1].sequence ?? 0) > currentStageSequence
    )
      this.nextStageId =
        this.stages?.filter((i) => i.sequence == currentStageSequence + 1)[0]
          .id ?? 1;
  }

  async onNextClick() {
    const disableForm = await firstValueFrom(
      this._customerRecoverySharedService.disableForm$
    );
    if (disableForm) {
      this.loadNextStage();
      return;
    }

    this.isSaveOperationInProgress = true;
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);

    const shouldShowLiabilityDetails =
      (await firstValueFrom(this.shouldShowLiabilityDetails$)) ?? false;
    const shouldShowIssueInvoiceScreen =
      (await firstValueFrom(this.shouldShowIssueInvoiceScreen$)) ?? false;

    const isCountryS4HanaMigrated =
      (await firstValueFrom(this.isCountryS4HanaMigrated$)) ?? false;

    // Adding this try catch to make sure we will always have the next button enabled if save operation fails.
    // TODO: The error handling needs to be re-looked for all the API calls.
    try {
      if (this.currentStageId === 1) {
        await this.stageOverviewSaveAndSubmit();
      } else if (this.currentStageId === 2) {
        await this.stageDamageEstimationSaveAndSubmit();
      } else if (this.currentStageId === 3 && shouldShowLiabilityDetails) {
        await this.stageLiabilitySave();
      } else if (this.currentStageId === 3 && !shouldShowLiabilityDetails) {
        await this.stageSubmit();
      } else if (this.currentStageId === 4 && !isCountryS4HanaMigrated) {
        await this.stageSubmit();
      } else if (
        this.currentStageId === 4 &&
        shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        await this.createInvoice();
      } else if (
        this.currentStageId === 4 &&
        !shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        let latestInvoices = await firstValueFrom(
          this._sharedRecoveryCaseService.invoices$
        );
        latestInvoices =
          this._sharedDataService.sortArrayByCreatedTimestamp(
            latestInvoices,
            false
          ) ?? [];

        if (
          latestInvoices[0].invoiceStatusCode &&
          [
            InvoiceStatusEnum.InvoiceIssued,
            InvoiceStatusEnum.PaymentReceived,
          ].includes(latestInvoices[0].invoiceStatusCode)
        ) {
          await this.stageSubmit();
        } else {
          this._toaster.showToast({
            message:
              'You can only complete the stage if the invoice has been issued or the payment has been received.',
            type: 'warning',
          });
        }
      }
    } catch (error) {
      console.error('Error occurred while saving data');
    } finally {
      this.isSaveOperationInProgress = false;
      this._loader.hideLoader();
    }
  }

  async createInvoice() {
    await this.invoiceLetterComponent.createInvoice();
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const invoiceRequest = {
      userName: userId,
      cancelationReason: this.caseDetails.cancellationReason ?? '',
      comment: this.caseDetails.comment ?? '',
      claimStatusId:
        this._customerRecoverySharedService.getStageId(WorkFlowStage.Invoice) ??
        1,
      updatedDttm: new Date(),
      workFlowStage: WorkFlowStage.Invoice,
    };
    this.claimStatusUpdate(invoiceRequest);
  }

  async stageOverviewSaveAndSubmit() {
    //overview details update
    const customerRecoveryCaseDto = await firstValueFrom(
      this._customerRecoverySharedService.customerRecoveryCaseData$
    );
    const workflowStageId = await firstValueFrom(
      this._customerRecoverySharedService.workflowStageId$
    );
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;

    if (!customerRecoveryCaseDto) return;

    await this.assignCurrentCaseToTheCurrentUser(customerRecoveryCaseDto);

    this.UpdateContainerDetails(customerRecoveryCaseDto);

    const currentItems = this.currentStageItem[this.currentTabIndex];
    const overViewDetails = currentItems.items?.filter(
      (item) => item.name == 'overview'
    )[0];
    const countries = await firstValueFrom(this._sharedDataService.countries$);
    overViewDetails?.items?.forEach((i: TemplateModel) => {
      switch (i.name) {
        case 'dateOfIncident':
          customerRecoveryCaseDto.dateOfIncident =
            this.convert_dd_mm_yyyy_into_dateFormat(i.value);

          break;
        case 'placeOfIncident':
          customerRecoveryCaseDto.placeOfIncident = i.value;
          break;
        case 'exchangerate':
          if (i.value)
            customerRecoveryCaseDto.exchangeRateUSDCaseCurrency = (
              i.value as string
            ).split(' ')[0] as unknown as number;
          break;
        case 'podLocalCurrency':
          if (i.value) customerRecoveryCaseDto.caseCurrency = i.value;
          break;
        case 'podCountryClusterCode':
          const selectedCluster = i.options?.find((c) => c.value == i.value);
          if (selectedCluster) {
            customerRecoveryCaseDto.podCountryClusterCode =
              selectedCluster?.value as string;
            customerRecoveryCaseDto.podCountryClusterName =
              selectedCluster?.label;
          }
          break;
        case 'podCountryCode':
          const selectedCountry = countries?.find((c) => c.code == i.value);
          if (selectedCountry) {
            customerRecoveryCaseDto.podCountryCode = selectedCountry.code;
            customerRecoveryCaseDto.podCountryId = selectedCountry.id;
            customerRecoveryCaseDto.podCountryName = selectedCountry.name;
            customerRecoveryCaseDto.podRegionCode = selectedCountry.regionCode;
            customerRecoveryCaseDto.podRegionName = selectedCountry.regionName;
          }
          break;
        case 'operatorCode':
          customerRecoveryCaseDto.operatorCode = i?.value as string;
          break;
      }
    });
    if (this.caseDetails?.recoveryCaseNumber)
      customerRecoveryCaseDto.recoveryCaseNumber =
        this.caseDetails?.recoveryCaseNumber;
    customerRecoveryCaseDto.workFlowVersion = 1; //temp version hard coded. When we get template this should be removed.
    const caseRequest = {
      customerRecoveryCaseDto: customerRecoveryCaseDto,
    } as CaseDetailsDto;

    const saveSuccess = await firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
    this._customerRecoverySharedService.reloadRecoveryCaseData();
    if (this.currentStageId == workflowStageId) {
      await this.updateRecoveryClaimId(
        {
          claimStatusId: this.nextStageId,
          userName: userId,
          updatedDttm: new Date(),
          comment: customerRecoveryCaseDto?.comments ?? '',
          cancelationReason: customerRecoveryCaseDto?.cancellationReason ?? '',
          workFlowStage: workflowStages[this.nextStageId],
        } as ClaimStatusRequest,
        this.nextStageId
      );
    } else {
      this._sharedRecoveryCaseService.reloadDamageDetails();
      this.loadNextStage();
    }
  }

  /**
   * Update Container details if missing in the case details.
   * @param customerRecoveryCaseDto CustomerRecoveryDetails
   */
  async UpdateContainerDetails(
    customerRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    const containerDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.containerDetails$
    );

    if (!containerDetails) return;

    customerRecoveryCaseDto.containerSize = containerDetails?.containerSize;
    customerRecoveryCaseDto.equipmentOwnershipType =
      containerDetails?.ownershipType;
    customerRecoveryCaseDto.equipmentSubType =
      containerDetails?.containerType?.containerTypeCode;
    customerRecoveryCaseDto.containerProdYear =
      containerDetails?.containerManufacturingYear;
  }

  /**
   * Assign this case to the current user if it is not already assigned.
   * @param customerRecoveryCaseDto CustomerRecoveryCaseDto
   * @returns Promise
   */
  async assignCurrentCaseToTheCurrentUser(
    customerRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (
      customerRecoveryCaseDto.assignedToUID === userId ||
      !customerRecoveryCaseDto.recoveryCaseNumber ||
      !this.caseDetails
    )
      return;

    const cases = [];
    const groupedCases = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsListByGroupIdPost(
        [customerRecoveryCaseDto.groupId ?? ''],
        this.apiVersion
      )
    );
    if (groupedCases && !!groupedCases.customerRecoveryDetails) {
      cases.push(
        ...groupedCases.customerRecoveryDetails.map(
          (recoveryCase: CustomerRecoveryCaseDto) =>
            recoveryCase.recoveryCaseNumber
        )
      );
    }
    await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseAssignmentPost(
          {
            assignedToUid: userId,
            recoveryCaseNumbers: cases,
            updatedBy: userId,
            updatedDttm: new Date(),
          },
          this.apiVersion
        )
        .pipe(
          tap((response: CaseAssignmentStatusResponse) => {
            if (
              response &&
              response.status?.statusCode == 200 &&
              response.caseAssignmentStatus?.isUpdateSuccessful &&
              this.caseDetails
            ) {
              this.caseDetails.assignedToUID = userId;
            }
          }),
          catchError((error: any) => {
            //returning true as we need to proceed other execution flow.
            return of(true);
          })
        )
    );
  }

  async stageDamageEstimationSaveAndSubmit() {
    const cpDetails = await firstValueFrom(
      this._customerRecoverySharedService.cpInformation$
    );
    await firstValueFrom(this.workOrdersComponent.saveWorkOrderLineItem());
    const recoveryDetails = this.caseDetails;
    if (
      cpDetails &&
      recoveryDetails &&
      cpDetails.cpCoverageCurrency == 'USD' &&
      this._customerRecoverySharedService.caseType ==
        CaseTypeEnum.CaseWithOutWorkOrder
    ) {
      recoveryDetails.aboveCoverageCostUSD = cpDetails.aboveCoverageCostUSD;
      recoveryDetails.recoverableCostUSD = cpDetails.recoverableCostUSD;
      recoveryDetails.withinCoverageCostUSD = cpDetails.withinCoverageCostUSD;
    }

    // If case does not have work order, save the case details as well as case details will have changes in that.
    if (
      this._customerRecoverySharedService.caseType !=
      CaseTypeEnum.CaseWithWorkOrder
    ) {
      await firstValueFrom(
        this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
          {
            customerRecoveryCaseDto: recoveryDetails,
          } as CaseDetailsDto,
          this.apiVersion
        )
      );
      this._sharedRecoveryCaseService.reloadRecoveryCaseData();
    }
    await this.damageDetailsComponent.saveDamageDetailsToServer();
    await this.stageSubmit();
  }

  async saveDamageDetails() {}

  async stageLiabilitySave() {
    //save liabilityParty after success send mail
    const response = await this.partyComponent.saveLiabilityParties();
    if (response.isSuccess) {
      await this._sharedRecoveryCaseService.reloadLiabilityPartyDetails();
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      await this.letterComponent.sendMail();
    }
  }

  async stageSubmit() {
    const workflowStageId = await firstValueFrom(
      this._customerRecoverySharedService.workflowStageId$
    );
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const statusReq = {
      claimStatusId: this.nextStageId,
      userName: userId,
      comment: this.caseDetails.comment ?? '',
      cancelationReason: this.caseDetails.cancellationReason ?? '',
      updatedDttm: new Date(),
      workFlowStage: workflowStages[this.nextStageId],
    } as ClaimStatusRequest;
    if (this.currentStageId == workflowStageId) {
      await this.updateRecoveryClaimId(statusReq, this.nextStageId);
    } else {
      this.loadNextStage();
    }
  }

  async closingCase() {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const { closeReason, comments } = this.closeCaseForm.value;
    const closeRequest = {
      userName: userId,
      cancelationReason: closeReason.reasonDescription,
      comment:
        closeReason.reasonDescription != this.OTHERS
          ? this.caseDetails.comment ?? ''
          : comments ?? '',
      claimStatusId: this.closedStatusId,
      updatedDttm: new Date(),
      workFlowStage: this._customerRecoverySharedService.getWorkFlowStageById(
        this.caseDetails.workflowStageId ?? 1
      ),
    };
    if ((this.caseDetails.workflowStageId ?? 1) == 1) {
      this.closingCaseWithWorkFlowVersionForNewCase(closeRequest);
    } else {
      this.claimStatusUpdate(closeRequest);
    }
  }

  async closingCaseWithWorkFlowVersionForNewCase(
    closeRequest: ClaimStatusRequest
  ) {
    let caseDetails = await firstValueFrom(
      this._customerRecoverySharedService.customerRecoveryCaseData$
    );
    if (!caseDetails) return;
    caseDetails.recoveryStatusId = closeRequest.claimStatusId;
    caseDetails.workFlowStage = closeRequest.workFlowStage;
    caseDetails.comments = closeRequest.comment;
    caseDetails.cancellationReason = closeRequest.cancelationReason;
    caseDetails.updatedBy = closeRequest.userName;
    caseDetails.updatedDate = closeRequest.updatedDttm;
    caseDetails.workFlowVersion = 1;
    caseDetails.updatedBy = closeRequest.userName;
    const caseDetailsReq = {
      customerRecoveryCaseDto: caseDetails,
    } as CaseDetailsDto;
    await this.assignCurrentCaseToTheCurrentUser(caseDetails);

    await firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseDetailsReq,
        this.apiVersion
      )
    );
  }
  async reOpeningCase() {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const closeRequest = {
      userName: userId,
      cancelationReason: '',
      comment: this.caseDetails.comment ?? '',
      claimStatusId: this.caseDetails.workflowStageId ?? 1,

      updatedDttm: new Date(),
      workFlowStage: this._customerRecoverySharedService.getWorkFlowStageById(
        this.caseDetails.workflowStageId ?? 1
      ),
    };
    this.claimStatusUpdate(closeRequest);
  }

  async claimStatusUpdate(request: ClaimStatusRequest) {
    const closedResponse: ClaimStatusResponse = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsClaimStatusCaseNumberPut(
        request,
        this.caseNumber,
        this.apiVersion
      )
    );
    if (!closedResponse.claimStatusChange.isClaimStatusChangeSuccessful) return;
    this._customerRecoverySharedService.reloadRecoveryCaseData();
  }

  onWorkOrdersLoaded(workOrders: WorkOrderAndLineItemsDto[]) {
    this.workOrders = workOrders;
  }

  onTabChange(event: any) {
    this.currentTabIndex = event.detail;
    this.tabItems = this.currentStageItem[event.detail].items;
  }

  onLiabilityPartySelected(event: SendMailRequest | undefined) {
    this.liabilityEmailContent = event;
  }

  onBackClick() {
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == this.currentStageId)[0].sequence ?? 0;
    const prevStage = this.stages?.filter(
      (i) => i.sequence == currentStageSequence - 1
    )[0];

    if (prevStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      if (prevStage.id)
        this._customerRecoverySharedService.updateCurrentStageId(prevStage.id);
    }
    this.loadTab = true;
    this._loader.hideLoader();
  }

  async updateRecoveryClaimId(request: ClaimStatusRequest, newStageId: number) {
    const statusResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsClaimStatusCaseNumberPut(
          request,
          this.caseNumber,
          this.apiVersion
        )
        .pipe(map((response) => response))
    );
    if (
      statusResponse.claimStatusChange &&
      statusResponse.claimStatusChange.isClaimStatusChangeSuccessful
    ) {
      this._customerRecoverySharedService.reloadRecoveryCaseData();
      this.loadNextStage();
    }
  }

  getWorkFlowStageById(claimId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (claimId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 2:
        stageName = WorkFlowStage.DamageEstimation;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 4:
        stageName = WorkFlowStage.Invoice;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  loadNextStage() {
    const newStage = this.stages?.filter(
      (i: any) => i.id == this.nextStageId
    )[0];
    if (newStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      this._customerRecoverySharedService.updateCurrentStageId(
        this.nextStageId
      );
    }
  }

  /**
   * Converting date to a specified format.
   * @param date date
   * @returns
   */
  private convert_dd_mm_yyyy_into_dateFormat(date: string) {
    if (!date) return undefined;
    const arrDate = date?.split('-');

    return new Date(
      `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${'00:00:00.000'}Z`
    );
  }
}
